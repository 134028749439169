@import "../../fonts/ProximaNova/stylesheet.css";
@import "../../fonts/CalsonPro/stylesheet.css";

:root {
  --margin-pagination: 10.2vh;
}
.container {
  width: 84%;
  height: 100vh;
  margin: 0px auto;
}
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .my-masonry-grid_column {
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 10.2vh;
  }

  .none {
    visibility: hidden;
    height: 23.4vh;
  }
  @media (max-width: 1024px) {
    .soon {
      font-size: calc(50px + 130 * (100vw / 1920));
    }
  }
  @media (max-width: 950px) {
    .none {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
    }
    .my-masonry-grid_column > div {
      margin-bottom: 4.2vh;
    }
    .description {
      margin-left: 0;

      .article {
        font-size: 4.5vh;
        line-height: 5.5vh;
      }
      .content {
        font-size: 2.5vh;
        line-height: 3vh;
      }
    }
    .soon {
      font-size: 8.5vh;
      line-height: 9.6vh;
    }
  }
}


.logo-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6.4vh;

  .logo-grid_column {
    padding-left: 1.3vw;
    background-clip: padding-box;
    width: auto;
    flex: 0 1 10%
  }
  .logoText {
    float: left;
    text-align: left;
    font-family: Adobe Caslon Pro, serif;
    font-style: normal;
    font-weight: normal;
    //font-size: 4.4vh;
    font-size: calc(29px + 19 * (100vw / 1920));
    line-height: 5.3vh;
    color: #FFFFFF;
    vertical-align: middle;
    margin-top: 9px;
  }
  .logoImage {
    margin-top: 6px;
    img {
      width: 3.84vw;
      height: 9.12vh;
    }
  }
  @media (max-width: 950px) {
    .logo-grid_column {
      padding-left: 0.3vw;
    }
    .logoText {
      line-height: 4.8vh
    }
    .logoImage {
      margin-top: 3px;
      img {
        width: auto;
      }
    }

  }
}

.soon {
  text-align: left;
  font-family: Proxima Nova Lt, serif;
  font-style: normal;
  font-weight: normal;
  //font-size: 18.5vh;
  font-size: calc(59px + 136 * (100vw / 1920));
  line-height: 22.6vh;
  color: #FFFFFF;
}

.description {
  text-align: left;
  margin-left: 6.1vw;

  .article {
    margin: 0px;

    font-family: Proxima Nova Lt, serif;
    font-style: normal;
    font-weight: normal;
    //font-size: 6vh;
    font-size: calc(32px + 28 * (100vw / 1920));
    line-height: 7vh;
    color: #FFFFFF;
  }

  .content {
    margin-block-start: 0.8em;
    font-family: Proxima Nova Lt, serif;
    font-style: normal;
    font-weight: normal;
    //font-size: 3.3vh;
    font-size: calc(16px + 20 * (100vw / 1920));
    line-height: 3.8vh;
    color: #FFFFFF;
  }
}

.pagination {
  position:absolute;
  top: 92.22%;
  bottom: 7.78%;
  left: 30%;
  right: 30%;

  .next {
    margin-left: 1.35vw;

    .nextArrow {
      margin-bottom: 3px;
    }
  }
  .previous {
    margin-right: 1.35vw;

    .previousArrow {
      margin-bottom: 3px;
    }
  }
}
@media (max-width: 950px) {
  .pagination{
    left: 20%;
    right: 20%;
  }
  .previousArrow {
    margin-bottom: 4px;
    width: 100px;
  }
  .nextArrow {
    margin-bottom: 4px;
    width: 100px;
  }
}
@media (max-width: 389px) {
  .previousArrow {
    padding-bottom: 1px;
    width: 90px;
  }
  .nextArrow {
    padding-bottom: 1px;
    width: 90px;
  }
  .soon {
    font-size: 9vh;
  }
}
@media (max-width: 320px) {
  .previousArrow {
    padding-bottom: 1px;
    width: 80px;
  }
  .nextArrow {
    padding-bottom: 1px;
    width: 80px;
  }
  .soon {
    font-size: 9vh;
  }
}
@media (max-width: 300px) {
  .previousArrow {
    padding-bottom: 1px;
    width: 70px;
  }
  .nextArrow {
    padding-bottom: 1px;
    width: 70px;
  }
  .soon {
    font-size: 9vh;
  }
}

.background {
  animation: fadein 1s;
}
@keyframes fadein {
  0% {opacity:0.4;}
  100% {opacity:1;}
}