/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-LightIt.woff') format('woff'),
		url('ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Semibold.woff') format('woff'),
		url('ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-SemiboldIt.eot');
	src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
		url('ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-SemiboldIt.woff') format('woff'),
		url('ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Light.woff') format('woff'),
		url('ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
